$main-color: #454ADE;

.login-logo {
  font-size: 32px;
  font-family: Montserrat;
  color: $main-color;
  text-align: center;
  text-transform: uppercase;
  margin: 20vh 0 3vh;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  cursor: hand;
  outline: 0;

  &:hover {
    color: #bbb;
  }
}

.ant-page-header {
  border: 1px solid rgb(235, 237, 240);
  border-radius: 4px;
  margin-bottom: 30px !important;

  .ant-page-header-heading {
    .ant-avatar-image {
      border-radius: 0;
    }

    .ant-page-header-heading-title {
      letter-spacing: -.5px;
      font-weight: 700;

      .ant-typography {
        color: #000;
      }
    }

    .ant-page-header-heading-sub-title {
      margin-top: 6px;
    }

    .ant-page-header-heading-tags {
      margin-top: 5px;
    }
  }
}

.ant-layout-sider-children,
.ant-layout-sider .ant-menu,
.ant-layout-sider-trigger {
  background: $main-color !important;
}

.ant-layout-sider .ant-menu {
  .ant-menu-item-selected {
    background: darken($main-color, 6%) !important;
  }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 4px 8px;
  cursor: pointer;
  border: 1px transparent solid;
}

.editable-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9;
  border-radius: 4px;
}

.ant-input-number {
  width: 100% !important;
}

.ant-table-content {
  th, td {
    white-space: nowrap !important;
  }
}

.ant-divider:before, .ant-divider:after {
  border-color: #eee !important;
}
