@import url('https://fonts.googleapis.com/css?family=Montserrat:700&display=swap');
@import 'overrides.scss';

.new-items-switch-label {
  font-size: 11px;
  font-weight: 600;
  padding: 3px 0 0 3px;
  text-transform: uppercase;
  cursor: pointer;
  cursor: hand;
}
